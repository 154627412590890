







































































































































































































































































































































































































































































































































import Repository from "./Repository"

import { IUpcoming } from './types'

import { Vue, Component } from 'vue-property-decorator'

@Component
export default class App extends Vue {

  messages: string[] | null = null

  timer:number = 0


  doCareform() {
      return Repository.post("careform").then(res => {
        // toast message is blank, toast will not be shown
        this.$bvToast.toast(res.data.content, res.data.options)
      })
    }


  doCancel(id: number) {
      return Repository.delete("events/" + id).then(res => {
        this.load()
        this.$bvToast.toast(res.data.content, res.data.options)
      })
    }


  doDelete(id: number) {
      return Repository.delete("events/" + id).then(res => {
        this.load()
        this.$bvToast.toast(res.data.content, res.data.options)
       })
    }


  load() {
    this.$store.dispatch("events")
  }

  logout() {
    /* it is possible you are already logged out OR that the server connection is dead */

    Repository.get("touch")
      .then(res => {
        if (res.status == 205)  {
          this.$store.dispatch("logout_public")
        }

        this.$store.dispatch("logout").then(() => {
          this.$root.$bvToast.toast("You are logged out", {
            title: "Logout",
            variant: "warning"
          })

          this.$router.push("/")
        })
      /* end touch */
      })
      .catch(() => {
        this.$router.push({ path: "/dead" })
      })

     
    }

/*
    onDeviceReady():any  {
      console.log(navigator.notification)
      navigator.notification.alert("Device is ready!", null, "Message", "OK")
      navigator.notification.beep(1)

    }
*/

    onResume():any  {

      if (!this.isMobile) {
        return false
      }

      let store = this.$store

      setTimeout(function() {
        // @ts-ignore
//        navigator.notification.alert("Device has resumed! Getting events", null, "Message", "OK")
        // @ts-ignore

        store.dispatch("events")
      }, 0)
    }

    onActive():any  {

      let store = this.$store

      setTimeout(function() {
        // @ts-ignore
        navigator.notification.alert("Device has activated! Getting events", null, "Message", "OK")
        // @ts-ignore

        store.dispatch("events")
      }, 0)
    }

  get menu_news(): boolean {
      if (this.$store.getters.pref.menu_news === "false") {
        return false
      }   

      return this.$store.getters.pref.menu_testimonial
    }

  get menu_testimonial(): boolean {
      if (this.$store.getters.pref.menu_testimonial === "false") {
        return false
      }   

      return this.$store.getters.pref.menu_testimonial
    }

  get menu_partners(): boolean {
      if (this.$store.getters.pref.menu_partners === "false") {
        return false
      }   

      return this.$store.getters.pref.menu_partners
    }

  get logo(): string {
      return this.$store.getters.pref.logo
    }

  get footer(): boolean {
      return this.$store.getters.pref.footer
    }

  get statedebug (): string {
      return this.$store.getters.pref.statedebug
    }

  get isLoggedIn(): boolean {
      return this.$store.getters.isLoggedIn
    }

  get isAdmin(): boolean {
      return this.$store.getters.isAdmin
    }

  get isEvent(): boolean {
      return this.$store.getters.isEvent
    }

  get isAccount(): boolean {
      return this.$store.getters.isAccount
    }

  get isPasshashReset(): boolean {
      return this.$store.getters.isPasshashReset
    }

  get token(): string {
      return this.$store.getters.token
    }

  get events(): any {
      return this.$store.getters.events
    }

  get upcoming(): IUpcoming {
      return this.$store.getters.upcoming
    }
  
  get authStatus(): string {
      return this.$store.getters.authStatus
    }

  get userData(): string[] {
      return this.$store.getters.userData
    }

  get message(): string {
      return this.$store.getters.message
    }

  get messageOptions(): string {
      return this.$store.getters.messageOptions
    }

  get currentRoute(): string {
      return this.$route.path
    }


  // See https://stackoverflow.com/questions/36822464/javascript-detect-ios-firefox
  get isFirefoxIOS(): boolean {
    if(/FxiOS/i.test(navigator.userAgent)) {
      return true
    }

    return false
  }


  get isMobile(): boolean {
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      return true
    }

    return false
  }


  // See: https://stackoverflow.com/questions/56578799/tell-ipados-from-macos-on-the-web
  get isIPad(): boolean {
    const ua = window.navigator.userAgent;
    if (ua.indexOf('iPad') > -1) {
        return true;
    }

    if (ua.indexOf('Macintosh') > -1) {
        try {
            document.createEvent("TouchEvent");
            return true;
        } catch (e) { /* There is nothing to be done */ }
    }

    return false;
}


  mounted() {
    console.log("App.vue is mounted. Doing restore_alt")
    this.$store.dispatch("restore_alt")
    this.$store.dispatch("pref")
    this.$store.dispatch("events") // Can be seen on nav

    Repository.get("message").then(err => (this.messages = err.data))

//    this.load()
    // not expected to necessarily run on iOS
    this.timer = setInterval(this.load, 10 * 60 * 1000) // every 10 minutes

    document.addEventListener("resume", this.onResume)
    document.addEventListener("active", this.onActive)
  }
  
  beforeDestory() {
    clearInterval(this.timer)
  }
}


// Cordova stuff

//
//document.addEventListener("resume", this.load, false)
// iOS only
//document.addEventListener("active", this.onResume, false)

