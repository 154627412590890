export default Object.freeze({
	//BASEURL : "http://localhost:8080/index.cfm/rest/",
	BASEURL : "https://beta.petcheckalert.com/index.cfm/rest/",
	APIKEY: "hawkfeedlaw",
	APIVERSION: "2020-10-01",

	jwt_secret : "qIc9N0Gf3hRhnZEzN51hCg8NFU9dw89F",
	header : {
		"alg": "HS256",
		"typ": null
	}
})
