import Vue from "vue";
import VueRouter from "vue-router";

import Home from "../views/Home.vue";
import Dead from "../views/Dead.vue";
import Login from "../views/Login.vue";
import Landing from "../views/Landing.vue";
import Logout from "../views/Logout.vue";
import NotFound from "../views/NotFound.vue";
import Unsubscribe from "../views/Unsubscribe.vue";
import Wiki from "../views/Wiki.vue";

//https://blog.sqreen.com/authentication-best-practices-vue/


Vue.use(VueRouter);

const routes = [
  { path: "/",        component: Home },
  { path: "/dead",    component: Dead },
  { path: "/login",   component: Login },
  { path: "/landing", component: Landing },
  { path: "/logout",  component: Logout },
  { path: "/unsubscribe/:id", component: Unsubscribe },
  { path: "/wiki/:id", component: Wiki },
  {
    path: "/contacts",
    name: "Contacts",
    component: () =>
      import(/* webpackChunkName: "profile" */ "../views/Contacts.vue")
  },
  {
    path: "/contactus",
    name: "ContactUs",
    component: () =>
      import(/* webpackChunkName: "contactus" */ "../views/ContactUs.vue")
  },
  {
    path: "/events/past",
    name: "EventsPast",
    component: () =>
      import(/* webpackChunkName: "events" */ "../views/Events.vue"),
    props: { selected: "Past" }
  },
  {
    path: "/events",
    name: "Events",
    component: () =>
      import(/* webpackChunkName: "events" */ "../views/Events.vue"),
    props: { selected: "Current" }
  },

  {
    path: "/event/M",
    name: "EventM",
    component: () =>
      import(/* webpackChunkName: "events" */ "../views/Event.vue"),
    props: { recurringtypeid: "Monthly" }
  },
  {
    path: "/event/W",
    name: "EventW",
    component: () =>
      import(/* webpackChunkName: "events" */ "../views/Event.vue"),
    props: { recurringtypeid: "Weekly" }
  },
  {
    path: "/event/D",
    name: "EventD",
    component: () =>
      import(/* webpackChunkName: "events" */ "../views/Event.vue"),
    props: { recurringtypeid: "Daily" }
  },
  {
    path: "/event/:id",
    name: "EventEdit",
    component: () =>
      import(/* webpackChunkName: "events" */ "../views/EventEdit.vue"),
  },
  {
    path: "/event",
    name: "Event",
    component: () =>
      import(/* webpackChunkName: "events" */ "../views/Event.vue"),
    props: { recurringtypeid: "One-time" }
  },
  {
    path: "/impersonate",
    name: "Impersonate",
    component: () =>
      import(/* webpackChunkName: "profile" */ "../views/Impersonate.vue")
  },
  {
    path: "/health",
    name: "Health",
    component: () =>
      import(/* webpackChunkName: "health" */ "../views/Health.vue")
  },
  {
    path: "/jour",
    name: "Jour",
    component: () =>
      import(/* webpackChunkName: "events" */ "../views/Jour.vue")
  },
  {
    path: "/partners",
    name: "Partners",
    component: () =>
      import(/* webpackChunkName: "contactus" */ "../views/Partners.vue")
  },
  {
    path: "/pets",
    name: "Pets",
    component: () =>
      import(/* webpackChunkName: "profile" */ "../views/Pets.vue")
  },
  {
    path: "/profile",
    name: "Profile",
    component: () =>
      import(/* webpackChunkName: "profile" */ "../views/Profile.vue")
  },
  {
    path: "/purchase",
    name: "Purchase",
    component: () =>
      import(/* webpackChunkName: "profile" */ "../views/Purchase.vue")
  },
  {
    path: "/resetpassword",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "profile" */ "../views/ResetPassword.vue")
  },
  {
    path: "/scheduler",
    name: "Scheduler",
    component: () =>
      import(/* webpackChunkName: "health" */ "../views/Scheduler.vue")
  },
  {
    path: "/settings",
    name: "Settings",
    component: () =>
      import(/* webpackChunkName: "profile" */ "../views/Settings.vue")
  },
  {
    path: "/square",
    name: "Square",
    component: () =>
      import(/* webpackChunkName: "signup" */ "../views/Square.vue")
  },
  {
    path: "/signup",
    name: "Signup",
    component: () =>
      import(/* webpackChunkName: "signup" */ "../views/Signup.vue")
  },
    { path: "*", component: NotFound }
];

const router = new VueRouter({
  routes
});

export default router;
