import axios from "axios"

import Config from './config'


export default axios.create({
	baseURL : Config.BASEURL,
	timeout : 30000,
	headers : { 
		"apiKey" 		: Config.APIKEY,
		"apiVersion" 	: Config.APIVERSION
	},
});
