

























































































import Repository from "../Repository"

import { Vue, Component } from 'vue-property-decorator'

@Component
export default class Login extends Vue {

  email = ""
  password = ""

  get emailstate(): boolean | null {
      if (this.email.length < 10) return null
      if (!this.validateEmail(this.email)) return false

      return true
    }

  get passwordstate(): boolean | null {
      if (this.password.length < 4) return null

      return true
    }


  login() {
      let email = this.email
      let password = this.password
      this.$store
        .dispatch("login", { email, password })
        .then(() => {
          if (this.$store.getters.isLoggedIn) {

            /*
            this.$bvToast.toast(
              this.$store.getters.message,
            )
            */
            // replaced with landing page

            // don't even think of restore or impersonate doing this
            this.$router.push("/landing")
          } else {
            console.log(this.$store.getters.message)

            this.$bvToast.toast(
              this.$store.getters.message,
            )
          }
        })
        .catch(err => console.log(err))
    }

    validateEmail(email: string) : boolean | null {
      var re = /\S+@\S+\.\S+/
      return re.test(String(email).toLowerCase())
    }


  mounted() {
    this.$store.dispatch("restore")

    // I don't trust this. It wants to put login success messages. It was fixed in store. 
    // BTW it was an old message not a phantom login
    if (this.$store.getters.message != "") {
      this.$bvToast.toast(
        this.$store.getters.message,
        this.$store.getters.messageOptions
        )

    // You are already loggedin. Why did you save this page?
    if (this.$store.getters.isLoggedIn) {
            this.$bvToast.toast(
              this.$store.getters.message,
            )
            this.$router.push("/events")
          }

    }

    Repository.get("touch")
      .then(res => {
        if (res.status == 205)  {
          this.$store.dispatch("logout_public")
        }
      })
      .catch(() => {
        this.$router.push({ path: "/dead" })
      })

  } // end mounted
}
