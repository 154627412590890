





































import Repository from "../Repository"

import { Vue, Component } from 'vue-property-decorator'

@Component
export default class Home extends Vue {

  slide: number = 0
  sliding = false

  onSlideStart() {
      this.sliding = true
    }

  onSlideEnd() {
      this.sliding = false
    }

  get  carousel() {
      return this.$store.getters.pref.carousel
    }

  mounted() {

    Repository.get("touch")
      .then(res => {
        if (res.status == 205)  {
          console.log("Home touch fail...")  

          this.$store.dispatch("logout_public")
        }
      })
      .catch(() => {
        this.$router.push({ path: "/dead" })
      })
  }
}
