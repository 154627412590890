
import { Vue, Component } from 'vue-property-decorator'

@Component
export default class Logout extends Vue {

  mounted() {
    this.$store
      .dispatch("logout")

    this.$router.push("/")
  }

}
