import Vue from "vue";
import App from "./App.vue";
import BootstrapVue from "bootstrap-vue";
import router from "./router";
import store from "./store";


// @ts-ignore:disable-next-line
import InputFacade from 'vue-input-facade'
Vue.use(InputFacade)




import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css"; // needed for toasts
//import "bootswatch/dist/pulse/bootstrap.min.css" // need for theming

// Whole thing
import { library } from "@fortawesome/fontawesome-svg-core";

import { faAsterisk } from "@fortawesome/free-solid-svg-icons";
import { faBan } from "@fortawesome/free-solid-svg-icons";
import { faBellSlash } from "@fortawesome/free-solid-svg-icons";
import { faBug } from "@fortawesome/free-solid-svg-icons";
import { faCat } from "@fortawesome/free-solid-svg-icons";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { faCloudSun } from "@fortawesome/free-solid-svg-icons";
import { faCoffee } from "@fortawesome/free-solid-svg-icons";
import { faCrow } from "@fortawesome/free-solid-svg-icons";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { faDog } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { faExternalLinkSquareAlt } from "@fortawesome/free-solid-svg-icons";
import { faFacebookSquare } from "@fortawesome/free-brands-svg-icons";
import { faFish } from "@fortawesome/free-solid-svg-icons";
import { faFrog } from "@fortawesome/free-solid-svg-icons";
import { faHorse } from "@fortawesome/free-solid-svg-icons";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { faMoon } from "@fortawesome/free-solid-svg-icons";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { faSms } from "@fortawesome/free-solid-svg-icons";
import { faStopCircle } from "@fortawesome/free-solid-svg-icons";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { faUserCog } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

// Solid
library.add(faAsterisk);
library.add(faBan);
library.add(faBellSlash);
library.add(faBug);
library.add(faCat);
library.add(faCircle);
library.add(faCloudSun);
library.add(faCoffee);
library.add(faCrow);
library.add(faCheckCircle);
library.add(faDog);
library.add(faEnvelope);
library.add(faExclamationCircle);
library.add(faExclamationTriangle);
library.add(faExternalLinkSquareAlt);
library.add(faFacebookSquare);
library.add(faFish);
library.add(faFrog);
library.add(faHorse);
library.add(faInfoCircle);
library.add(faMoon);
library.add(faPlus);
library.add(faPhone);
library.add(faSms);
library.add(faStopCircle);
library.add(faTimesCircle);
library.add(faTrash);
library.add(faUser);
library.add(faUserCog);

// Product Promo
import { faAddressBook } from "@fortawesome/free-solid-svg-icons";
import { faAllergies } from "@fortawesome/free-solid-svg-icons";
import { faAudioDescription } from "@fortawesome/free-solid-svg-icons";
import { faAtom } from "@fortawesome/free-solid-svg-icons";
import { faBaby } from "@fortawesome/free-solid-svg-icons";
import { faBaseballBall } from "@fortawesome/free-solid-svg-icons";
import { faBone } from "@fortawesome/free-solid-svg-icons";
import { faBullhorn } from "@fortawesome/free-solid-svg-icons";
import { faCarBattery } from "@fortawesome/free-solid-svg-icons";
import { faCertificate } from "@fortawesome/free-solid-svg-icons";
import { faFilm } from "@fortawesome/free-solid-svg-icons";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";
import { faPaperclip } from "@fortawesome/free-solid-svg-icons";
import { faPhoneVolume } from "@fortawesome/free-solid-svg-icons";
import { faPenFancy } from "@fortawesome/free-solid-svg-icons";
import { faStickyNote } from "@fortawesome/free-solid-svg-icons";

library.add(faAddressBook);
library.add(faAllergies);
library.add(faAudioDescription);
library.add(faAtom);
library.add(faBaby);
library.add(faBaseballBall);
library.add(faBone);
library.add(faBullhorn);
library.add(faCarBattery);
library.add(faCertificate);
library.add(faFilm);
library.add(faGlobe);
library.add(faPaperclip);
library.add(faPhoneVolume);
library.add(faPenFancy);
library.add(faStickyNote);


Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.use(BootstrapVue);

Vue.config.productionTip = false;

// @ts-ignore:disable-next-line
Vue.use(library);







const vueApp = new Vue({
  router,
  store,

  methods: {
    onDeviceReady: function() {
      //               alert('Device is ready!');
    }
  },
  render: h => h(App)
}).$mount("#app");

document.addEventListener("deviceready", vueApp.onDeviceReady);
