











import Repository from "../Repository"

import { Vue, Component } from 'vue-property-decorator'

@Component
export default class Home extends Vue {

  mounted() {

    Repository.get("touch")
      .then(() => {
       // this would have thrown an error if it were dead. 205 is fine
       console.log("It's alive! It's alive!")
        this.$router.push({ path: "/" })
      })

  }
}
