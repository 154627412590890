























































import Repository from "../Repository"

import { Vue, Component } from 'vue-property-decorator'

@Component
export default class Landing extends Vue {

  loading = false
  page = String()
  error =  String()


// In the old days, this would be a toast
get message(): any {
      return this.$store.getters.message
    }

get messageOptions(): any {
  return this.$store.getters.messageOptions
  }

get isPasshashReset(): boolean {
    return this.$store.getters.isPasshashReset
  }    

get events()  {
      return this.$store.getters.events
    }


    // methods
  mounted() {
    this.error = this.page = ""
    this.loading = true

    
    Repository.get(`page/landing`)
      .then(res => (this.page = res.data))
      .catch(error => (this.error = error))

    // no need for landing because you have stuff
    Repository.get(`events`)
      .then(res => {
        console.log(JSON.stringify(res.data.events.length))
        if (res.data.events.length > 0) {
          this.$router.push({ path: "/events" })
        }
      }) 

    this.$store.dispatch("events")

    this.loading = false
    }

}
