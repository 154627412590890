































import Repository from "../Repository"

import { Vue, Component, Watch } from 'vue-property-decorator'

@Component
export default class Wiki extends Vue {

  loading = false
  page = String()
  error =  String()

  created() {
    // fetch the data when the view is created and the data is
    // already being observed
    this.fetchData()
  }

  @Watch('$route')
  onRouteChanged() { this.fetchData() }

  // methods
  fetchData() {
    this.error = this.page = ""
    this.loading = true

    // replace `getPost` with your data fetching util / API wrapper
    Repository.get(`page/${this.$route.params.id}`)
      .then(res => (this.page = res.data))
      .catch(error => (this.error = error))

    this.loading = false
    }

  mounted() {

    Repository.get("touch")
      .then(res => {
        if (res.status == 205)  {
          this.$store.dispatch("logout_public")
        }
      })
      .catch(() => {
        this.$router.push({ path: "/dead" })
      })
  }
}
