import Vue from "vue";
import Vuex from "vuex";
import router from "../router";

import Repository from "../Repository";
import { ILoginResponse, toastOptionsType } from '@/types';

Vue.use(Vuex);

// based on https://github.com/christiannwamba/vue-auth-vuex/blob/master/src/store.js

export default new Vuex.Store({
  state: {
    pref: Object(),
    grid: [],
    events: Object(),
    upcoming: Object(),

    // user specific
    status: Object(),

    message: String(),
    messageOptions: Object(), 
    token: window.localStorage.getItem("token") || null,
    isRestoring: Boolean(),
    isPasshashReset: Boolean(),
    roles: Array<string>()
  },

  mutations: {
    pref(state, data) {
      state.pref = data.pref
      state.grid = data.pref.grid
    },

    events(state, data) {
      state.events = data.events
    },

    upcoming(state, data) {
      state.upcoming = data.upcoming
    },

    upcoming_clear(state) {
      state.upcoming = {}
    },


    // Login
    auth_request(state) {
      state.status = { content: "Loading..." }
    },

    restore_start(state) {
      state.isRestoring = true
    },

    restore_success(state, data) {
      state.isRestoring = false
      state.roles = data.roles
    },

    auth_success(state, data:ILoginResponse) {

      console.log("Mutation: auth_success")

      state.message = data.content
      state.messageOptions = data.options
      state.token = data.token
      state.roles = data.roles
      state.isPasshashReset = data.isPasshashReset ? <any>true : <any>false
      
    },

    auth_error(state, data:ILoginResponse) {

      state.message = data.content
      state.messageOptions = data.options as toastOptionsType
      state.isPasshashReset = <any>false

      state.token = null
      state.roles = []
    },

    passhashreset_clear(state) {
      state.isPasshashReset = <any>false
    },

    // pushes messages to the next page
    // Hmmm, so what shows it.
    message_nextpage(state, data) {

      state.message = data.content
      state.messageOptions = data.options as toastOptionsType
    },


    impersonate_error(state, data:ILoginResponse) {

      state.message = data.content
      state.messageOptions = data.options as toastOptionsType
    },

    // Used when wandering around public pages. It could change menus
    logout_public(state) {

      state.message = ""
      state.messageOptions = { }

      state.status = {}
      state.token = null
      state.roles = []
    },


    // Used when wandering around private pages
    logout_private(state) {

      state.message = "Authentication Error, you must login again."
      state.messageOptions = { "variant" : "warning", "title" : "Warning" }

      state.status = {}
      state.token = null
      state.roles = []

      router.push({ path: "/login" })
    },

    // used for logout link
    logout(state) {
      console.log("Doing logout...")
      window.localStorage.removeItem("token")
      state.message = ""
      state.messageOptions = ""

      state.status = {}
      state.token = null
      state.roles = []

      router.push({ path: "/login" })
    }
  },

  // we need action because they can be ...
  actions: {
    pref({ commit }) {
      return new Promise(resolve => {
        Repository.get("pref").then(res => {
          const pref = res.data;
          commit("pref", { pref })
        });
        resolve()
      });
    },

    message_nextpage({ commit }, message) {

      commit("message_nextpage",  message )
    },

    events({ commit }) {

      if (!this.state.token)  {
        console.log("action: events - no token")
        return
      }

      if (!this.state.roles.length) {
        console.log("no permissions")
        return
      }

      return new Promise(resolve => {
        Repository.get("events?stm=timer").then(res => {

          if (res.status == 205)  {
            commit("logout_private")
            return
          }

          const events = res.data.events

          commit("events", { events })

          if (events.length == 0) {
            console.log("There are no events to show. Go to event add page")
    
            router.push({ path: "/event" })
          }

          if(Object.prototype.hasOwnProperty.call(res.data, "upcoming")) {
            const upcoming = res.data.upcoming
            commit("upcoming", { upcoming })
          }
  
          else{
            commit("upcoming_clear")
          }
        })
        resolve()
      });
    },

    // restore
    restore({ commit }) {
      commit("restore_start")

      console.log("attempting restore")

      const token = window.localStorage.getItem("token");

      console.log("Token is: " + token)

      // checks for not null and not undefined
      if (!!token && !this.state.roles.length ) {
        return new Promise(resolve => {
          Repository.defaults.headers.common["Authorization"] = token;

          Repository.get("profile").then(res => {
            console.log("While doing restore, status code was " + res.status)
            if (res.status == 205) {
              console.log("So I am going to commit('logout')")
            //  commit("logout")
            }

            if (Object.prototype.hasOwnProperty.call(res, "data")) {

              const roles = res.data.roles

              commit("restore_success", { roles })
            }
          });
          resolve();
        });
      } // end token check
    },

    // Does not redirect
    restore_alt({ commit }) {
      commit("restore_start")

      const token = window.localStorage.getItem("token");

      console.log("Inside of restore_alt, token is: " + token)

      // checks for not null and not undefined
      if (!!token && !this.state.roles.length ) {
        return new Promise(resolve => {
          Repository.defaults.headers.common["Authorization"] = token;

          Repository.get("profile").then(res => {
            if (Object.prototype.hasOwnProperty.call(res, "data")) {

              const roles = res.data.roles

              commit("restore_success", { roles })
            }
          });
          resolve();
        });
      } // end token check
    },

    login({ commit }, user) {
      return new Promise(resolve => {
        commit("auth_request");

        Repository.post("login", user)
          .then(res => {
            const token   = "Bearer " + res.data.token
            const roles   = res.data.roles
            const content = res.data.content
            const options = res.data.options
            const isPasshashReset = res.data.isPasshashReset

            if (roles.length == 0) {
              commit("auth_error", { content, options })
              window.localStorage.removeItem("token")
            } 
            else {
              commit("auth_success", { roles, token, content, options, isPasshashReset })
              console.log("Long term token")
              window.localStorage.setItem("token", token)

              console.log("Token is: " + window.localStorage.getItem("token"))

              // Add the following line:
              Repository.defaults.headers.common["Authorization"] = token
            }
            resolve(res)
            // end then
          })
          .catch(err => {
            const message = { "content" : err }
            commit("auth_error", { message });
            window.localStorage.removeItem("token");
          });
      });
    },

    impersonate({ commit }, user) {
      return new Promise(resolve => {
        commit("auth_request");

        Repository.post("impersonate", user)
          .then(res => {
            const token = "Bearer " + res.data.token;
            const roles   = res.data.roles;
            const content = res.data.content;
            const options = res.data.options;

            if (typeof roles == "undefined" || roles.length == 0) {
              commit("impersonate_error", { content, options });
            } else {
              window.localStorage.setItem("token", token);

              commit("auth_success", { roles, token, content, options });

              // Add the following line:
              Repository.defaults.headers.common["Authorization"] = token;
            }
            resolve(res);
            // end then
          })
          .catch(err => {
            const message = { "content" : err }
            commit("impersonate_error", { message });
          });
      });
    },

    placeholder({ commit }, user) {
      console.log("Doing the placeholder...")
      return new Promise(resolve => {
        commit("auth_request");

        Repository.put("placeholder", user)
          .then(res => {
            const token = "Bearer " + res.data.token;
            const roles   = res.data.roles; // expected to be empty
            const content = res.data.content;
            const options = res.data.options;

            window.localStorage.setItem("token", token);

            commit("auth_success", { roles, token, content, options });

            // Add the following line:
            Repository.defaults.headers.common["Authorization"] = token;
            
            resolve(res);
            // end then
          })
          .catch(err => {
            const message = { "content" : err }
            commit("impersonate_error", { message });
          });
      });
    },

    logout_public({ commit }) {

      console.log("logout_public")

      return new Promise(resolve => {
        window.localStorage.clear();
        delete Repository.defaults.headers.common["Authorization"];
        resolve();

        commit("logout_public");
      }); // end return
    },

    logout_private({ commit }) {

      console.log("logout_private")
  
      return new Promise(resolve => {
        window.localStorage.clear();
        delete Repository.defaults.headers.common["Authorization"];
        resolve();

        commit("logout_private");
      }); // end return
    },


    logout({ commit }) {

      console.log("logout")

      Repository.delete("login")

      return new Promise(resolve => {
        window.localStorage.clear();
        delete Repository.defaults.headers.common["Authorization"];
        resolve();

        commit("logout");
      }); // end return
    }
  }, // end actions

  getters: {
    pref: state => state.pref,
    grid: state => state.grid,
    events: state => state.events,
    upcoming: state => state.upcoming,
    message: state => state.message,
    messageOptions: state => state.messageOptions,

    // login
    isLoggedIn: state => !!state.token,
    isAdmin:    state => !!state.token && typeof state.roles !== 'undefined' && state.roles.indexOf("admin") != -1,
    isEvent:    state => !!state.token && typeof state.roles !== 'undefined' && state.roles.indexOf("event") != -1,
    isAccount:  state => !!state.token && typeof state.roles !== 'undefined' && state.roles.indexOf("account") != -1,
    isRestoring: state => state.isRestoring,
    isPasshashReset: state => state.isPasshashReset,
    token:      state => state.token,
    authStatus: state => state.status,
    userData:   state => state.roles
  }
});
