






import Repository from "../Repository"

import { Vue, Component } from 'vue-property-decorator'

@Component
export default class Wiki extends Vue {


  created() {
    // fetch the data when the view is created and the data is
    // already being observed
    this.fetchData()
  }


  // methods
  fetchData() {

    // replace `getPost` with your data fetching util / API wrapper
    Repository.put(`unsubscribe/${this.$route.params.id}`)
      .then(res => {
            this.$bvToast.toast(res.data.content, res.data.options)
            setTimeout( () => this.$router.push("/"), 5000)
          })

    }
}
